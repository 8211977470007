<script lang="ts">
export default {
  name: 'QuickSearchInput',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, inject, ref, Ref } from 'vue';
import SearchInput from '@/components/atoms/SearchInput/Index.vue';
import { IQuickSearchResults } from '@/components/molecules/QuickSearchResults/QuickSearchResult.vue';
import { useApiClient } from '@/composables/useApiClient';
import { ISearchData, searchDataKey } from '@/types/InjectionKeys';

const searchData: ISearchData | undefined = inject(searchDataKey);
const { apiClient } = useApiClient();

const props = defineProps<{
  modelValue: string;
  searchPath: string;
  contentDiscoverabilitySearch: boolean;
}>();

const searchResults: Ref<IQuickSearchResults[] | undefined> = ref();
window.emitter.on('update:quickSearchResults', (newQuickSearchResults: IQuickSearchResults[]) => {
  searchResults.value = newQuickSearchResults;
});

const handleSearch = async () => {
  if (props.modelValue) {
    await saveRecentSearch();
  }

  window.location.href = generateSearchPath();
};

/*
 Both search types require data from the search result object:
 Original search                - Find the first entity type with values and return the path
 Content discoverability search - Takes the entity type of the first result and appends it to the search path
*/
const generateSearchPath = () => {
  let foundResult;
  if (searchResults.value && searchResults.value.length > 0) {
    searchResults.value.some((result) => {
      if (result.values.length > 0 && result.path && result.entity) {
        foundResult = { path: result.path, entity: result.entity };
        return true;
      }
    });
  }

  // New content discoverability search
  if (props.contentDiscoverabilitySearch) {
    const contentSearchPath = new URL(props.searchPath.substring(1), window.location.origin);
    if (foundResult) {
      contentSearchPath.searchParams.append('entity', foundResult.entity);
    }
    contentSearchPath.searchParams.append('search', props.modelValue);
    return contentSearchPath.href;
  }

  // Original search
  if (foundResult) {
    return foundResult.path;
  }

  // Fallback - Content discoverability off, empty search term or no results
  return new URL('/items', window.location.origin);
};

const saveRecentSearch = async () => {
  if (!searchData) {
    return;
  }

  const params = {
    search: {
      term: props.modelValue,
    },
  };

  await apiClient.post(searchData.searchesPath, params);
  window.emitter.emit('update:recentSearches');
};
</script>

<template>
  <SearchInput
    v-bind="$props"
    :model-value="modelValue"
    class="quick-search__search-input"
    placeholder="Search"
    data-test-id="quick-search-input"
    @update:modelValue="($event) => $emit('update:modelValue', $event)"
    @focus="(e) => $emit('focus', e)"
    @blur="(e) => $emit('blur', e)"
    @enter="handleSearch"
  />
  <slot />
</template>

<style lang="scss" scoped>
.quick-search__search-input {
  width: 100%;
}
</style>
