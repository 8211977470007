<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { PrimaryNavLink } from '@/json_schema_types/sidebar_navigation';
import { useTestHelpers } from '@/composables/useTestHelpers';

interface Props {
  link: PrimaryNavLink;
  collapsed: boolean;
  highlighted?: boolean;
}

const props = defineProps<Props>();
const { generateTestId } = useTestHelpers();
const faIconString = computed(() => {
  return props.highlighted ? `fa-solid ${props.link.icon}` : `fa-regular ${props.link.icon}`;
});
</script>

<template>
  <li>
    <button
      class="navigation-button__button"
      :data-test-id="generateTestId('side-navigation-link', link.label)"
      @click="$emit('openSubNav')"
    >
      <FontAwesomeIcon
        v-if="link.icon"
        :icon="faIconString"
        :data-test-id="generateTestId('navigation button icon', link.label)"
        size="lg"
      />
      <span :class="{ 'visually-hidden': collapsed }">{{ link.label }}</span>
    </button>
  </li>
</template>

<style scoped lang="scss">
.navigation-button__button {
  all: unset;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: var(--side-navigation-item-padding);
  gap: var(--side-navigation-icon-spacing);
  text-wrap: nowrap;
  border-radius: 4px;
}
</style>
