<script lang="ts">
export default {
  name: 'AddNew',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import { useI18n } from '@/composables/useI18n';
import { AddNewLinkGroup } from '@/json_schema_types/sidebar_navigation';

const { t } = useI18n();
const addNewOpen = ref(false);

interface Props {
  addNewItems?: AddNewLinkGroup[];
}

defineProps<Props>();
</script>

<template>
  <NavigationHeaderItem
    highlighted
    :aria-label="t('vue_templates.user_account_menu.aria_label')"
    aria-haspopup="true"
    :aria-expanded="addNewOpen"
    icon="fa-circle-plus"
    aria-controls="add-new-popover-menu"
    class="user-account-menu__button"
    @click.prevent="addNewOpen = !addNewOpen"
    @keyup.enter="addNewOpen = !addNewOpen"
  >
  </NavigationHeaderItem>
  <PopoverMenu
    v-if="addNewOpen"
    class="add-new__popover-menu"
    position="bottom"
    toggle-element-selector="user-account-menu__button"
    :model-value="addNewOpen"
    max-width="1000px"
    max-height="1000px"
    right="5rem"
    @update:model-value="addNewOpen = $event"
  >
    <div
      v-for="(addNewItem, index) in addNewItems"
      :key="`add-new-button-${index}`"
      class="add-new__popover-menu-content"
    >
      <span class="add-new__title">{{ addNewItem.group_title }}</span>
      <div class="add-new__link-container">
        <a
          v-for="link in addNewItem.links"
          :key="`add-new-${link.label}`"
          class="add-new__link"
          :style="`background-color: ${link.color};`"
        >
          {{ link.label }}
        </a>
      </div>
    </div>
  </PopoverMenu>
</template>

<style lang="scss" scoped>
.add-new__popover-menu {
  color: $jet;
}

.add-new__popover-menu-content {
  padding: 1rem 3rem;
}

.add-new__title {
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
  padding-bottom: 1rem;
}

.add-new__link-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.add-new__link {
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 3rem;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  border-radius: 4px;
  font-weight: 500;
  color: $jet;
}
</style>
