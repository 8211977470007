<script lang="ts">
export default {
  name: 'NotificationsDropdown',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue';
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { UtilityNavItems } from '@/json_schema_types/sidebar_navigation';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';

interface Props {
  utilityNavItems: UtilityNavItems;
}
const props = defineProps<Props>();

const { t } = useI18n();
const { apiClient } = useApiClient();
const notificationsDropdownOpen = ref(false);
const loading = ref(true);
const notifications = ref();
const unreadCount = ref(props.utilityNavItems.notifications.unread_count);

const getData = () => {
  apiClient
    .get(props.utilityNavItems.notifications.endpoint, { params: { per: 5 } })
    .then((response) => {
      notifications.value = response.data;
      unreadCount.value = 0;
      loading.value = false;
    });
};

watch(notificationsDropdownOpen, (isOpen) => {
  if (isOpen && !notifications.value) {
    getData();
  }
});
</script>

<template>
  <NavigationHeaderItem
    :aria-label="t('activerecord.models.notification.other')"
    aria-haspopup="true"
    :aria-expanded="notificationsDropdownOpen"
    :icon="utilityNavItems.notifications.icon"
    aria-controls="add-new-popover-menu"
    class="user-account-menu__button"
    :badge-count="unreadCount"
    @click.prevent="notificationsDropdownOpen = !notificationsDropdownOpen"
    @keyup.enter="notificationsDropdownOpen = !notificationsDropdownOpen"
  >
  </NavigationHeaderItem>
  <PopoverMenu
    v-if="notificationsDropdownOpen"
    class="add-new__popover-menu"
    position="bottom"
    toggle-element-selector="user-account-menu__button"
    :model-value="notificationsDropdownOpen"
    right="15rem"
    @update:model-value="notificationsDropdownOpen = $event"
  >
    <template v-if="loading">
      <div v-for="i in 3" :key="i" class="notification-dropdown__message-list">
        <div class="skeleton-loading"></div>
        <div class="skeleton-loading"></div>
        <div class="skeleton-loading xsmall w-40p"></div>
      </div>
    </template>
    <div v-else-if="notifications.length === 0" class="notification-dropdown__no-messages center">
      <span class="text-muted text-center m-y-2">
        {{ t('vue_templates.notifications_navigation.no_notifications') }}
      </span>
    </div>
    <template v-else>
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="notification-dropdown__message-list"
      >
        <div v-html="notification.sentence" />
        <div class="notification-dropdown__message-date">
          {{ notification.created_at }}
        </div>
      </div>
      <div class="notification-dropdown__view-all center">
        <a :href="utilityNavItems.notifications.url">
          {{ t('vue_templates.notifications_navigation.view_all') }}
        </a>
      </div>
    </template>
  </PopoverMenu>
</template>

<style lang="scss" scoped>
.notification-dropdown__message-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: #31353d;

  .skeleton-loading {
    height: 15px;
  }
}

.center {
  text-align: center;
}

.notification-dropdown__message-date {
  color: #9c9c9c;
}

.notification-dropdown__message-list:first-of-type {
  padding-top: 0;
}

.notification-dropdown__message-list:not(:last-of-type) {
  border-bottom: 1px solid $platinum;
}

.notification-dropdown__no-messages {
  padding: 3rem 1rem;
}

.notification-dropdown__view-all {
  padding: 1rem 1rem 0;
}
</style>
