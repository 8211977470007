<script lang="ts">
export default {
  name: 'UserAccountMenu',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, ref, Ref } from 'vue';
import Avatar from '@/components/atoms/Avatar/Index.vue';
import FullDescription from '@/components/molecules/Avatar/FullDescription.vue';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';
import PopoverMenuDivider from '@/components/atoms/Popover/PopoverMenuDivider/Index.vue';
import PopoverMenuItem from '@/components/atoms/Popover/PopoverMenuItem/Index.vue';
import PopoverMenuButton from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuButton.vue';
import PopoverMenuLink from '@/components/atoms/Popover/PopoverMenuItem/PopoverMenuLink.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import { useI18n } from '@/composables/useI18n';
import { ICurrentUser } from '@/types/Navigation';
import { UserMenu, BaseMenuLink } from '@/json_schema_types/sidebar_navigation';
import { useApiClient } from '@/composables/useApiClient';
interface Props {
  currentUser: ICurrentUser;
  userMenu: UserMenu;
}

const props = defineProps<Props>();
const { t } = useI18n();
const { apiClient } = useApiClient();

const menuOpen: Ref<boolean> = ref(false);
const privacySubMenuOpen: Ref<boolean> = ref(false);
const languageSubMenuOpen: Ref<boolean> = ref(false);

const signOut = () => {
  apiClient.delete(props.currentUser.signOutPath).then(() => {
    window.location.href = '/';
  });
};
</script>

<template>
  <div class="user-account-menu">
    <NavigationHeaderItem
      :aria-label="t('vue_templates.user_account_menu.aria_label')"
      aria-haspopup="true"
      :aria-expanded="menuOpen"
      aria-controls="user-account-popover-menu"
      class="user-account-menu__button"
      @click.prevent="menuOpen = !menuOpen"
      @keyup.enter="menuOpen = !menuOpen"
    >
      <Avatar :src="currentUser.avatar" size="small" /><span class="user-account-menu__full-name">{{
        currentUser.name
      }}</span>
    </NavigationHeaderItem>
    <PopoverMenu
      id="user-account-popover-menu"
      class="user-account-menu__popover-menu"
      position="bottom"
      toggle-element-selector="user-account-menu__button"
      max-width="400px"
      :model-value="menuOpen"
      @update:model-value="menuOpen = $event"
    >
      <PopoverMenuItem>
        <FullDescription
          :avatar-url="currentUser.avatar"
          :full-name="currentUser.full_name || currentUser.name"
        />
      </PopoverMenuItem>
      <PopoverMenuDivider />
      <!-- Account switcher placeholders: TODO: new account switcher component -->
      <PopoverMenuItem class="account-switcher-placeholders">
        <BaseButton
          variant="icon"
          prepend-icon="learnamp"
          size="large"
          theme="learnamp"
          data-test-id="account-switcher-placeholder-icon"
          aria-label="Select account"
        />
        <BaseButton
          variant="icon"
          prepend-icon="learnamp"
          size="large"
          theme="learnamp"
          data-test-id="account-switcher-placeholder-icon"
          aria-label="Select account"
        />
        <BaseButton
          variant="icon"
          prepend-icon="t-add-circle"
          size="large"
          theme="info"
          aria-label="Add another account"
          data-test-id="account-switcher-placeholder-icon"
        />
      </PopoverMenuItem>
      <PopoverMenuDivider />
      <PopoverMenuItem>
        <PopoverMenuLink
          :item="{
            url: currentUser.show_path,
            label: t('vue_templates.user_account_menu.profile'),
            icon: 'fa-user',
          }"
        />
      </PopoverMenuItem>
      <PopoverMenuItem>
        <PopoverMenuLink
          :item="{
            url: currentUser.preferencesPath,
            label: t('vue_templates.user_account_menu.preferences'),
            icon: 'fa-gear',
          }"
        />
      </PopoverMenuItem>
      <PopoverMenuDivider />
      <PopoverMenuItem>
        <PopoverMenuButton
          :item="{
            label: t('vue_templates.user_account_menu.language'),
            icon: 'fa-language',
          }"
          aria-haspopup="true"
          aria-controls="privacy-and-policies-popover-menu"
          :aria-expanded="languageSubMenuOpen"
          class="user-account-menu__language-button"
          :has-sub-menu="true"
          @click="languageSubMenuOpen = !languageSubMenuOpen"
          @keyup.enter="languageSubMenuOpen = !languageSubMenuOpen"
        />
        <PopoverMenu
          id="privacy-and-policies-popover-menu"
          position="left"
          toggle-element-selector="user-account-menu__language-button"
          :model-value="languageSubMenuOpen"
          @update:model-value="languageSubMenuOpen = $event"
        >
          <PopoverMenuItem> Language selector goes here. </PopoverMenuItem>
        </PopoverMenu>
      </PopoverMenuItem>
      <PopoverMenuItem v-if="userMenu.compliance_navigation_items">
        <PopoverMenuButton
          :item="{
            label: t('vue_templates.user_account_menu.privacy_and_policies'),
            icon: 'fa-scale-unbalanced-flip',
          }"
          :has-sub-menu="true"
          aria-haspopup="true"
          aria-controls="privacy-and-policies-popover-menu"
          class="user-account-menu__privacy-and-policies-button"
          :aria-expanded="privacySubMenuOpen"
          @click="privacySubMenuOpen = !privacySubMenuOpen"
          @keyup.enter="privacySubMenuOpen = !privacySubMenuOpen"
        />
        <PopoverMenu
          id="privacy-and-policies-popover-menu"
          position="left"
          toggle-element-selector="user-account-menu__privacy-and-policies-button"
          :model-value="privacySubMenuOpen"
          @update:model-value="privacySubMenuOpen = $event"
        >
          <template v-for="(item, index) in userMenu.compliance_navigation_items" :key="index">
            <PopoverMenuItem>
              <PopoverMenuLink :item="item as BaseMenuLink" />
              <FontAwesomeIcon icon-name="fa-arrow-up-right-from-square" size="lg" />
            </PopoverMenuItem>
          </template>
        </PopoverMenu>
      </PopoverMenuItem>
      <PopoverMenuDivider />
      <PopoverMenuItem>
        <PopoverMenuButton
          :item="{
            label: t('vue_templates.user_account_menu.sign_out'),
            icon: 'fa-arrow-right-from-bracket',
          }"
          @click.prevent="signOut"
          @keyup.prevent.enter="signOut"
        />
      </PopoverMenuItem>
    </PopoverMenu>
  </div>
</template>

<style lang="scss" scoped>
.user-account-menu {
  color: inherit;
  margin-left: 3rem;
  position: relative;

  &__full-name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }

  &__popover-menu {
    top: calc(100% + 1rem);
  }
}
</style>
