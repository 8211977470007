<script lang="ts">
export default {
  name: 'ChevronBackButton',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
import Chevron from '@/components/atoms/NavigationChevron/Index.vue';
defineProps<{ srLabel?: string }>();
</script>

<template>
  <button class="chevron-back-button">
    <Chevron direction="left" theme="light">
      <span class="visually-hidden">{{ srLabel || '' }}</span>
    </Chevron>
  </button>
</template>

<style lang="scss" scoped>
.chevron-back-button {
  all: unset;
  cursor: pointer;
  margin: 2rem 1.5rem 0;
  padding: 0.5rem;
  width: max-content;

  &:hover {
    background-color: $platinum;
    border-radius: 4px;
  }
}
</style>
