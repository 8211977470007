<template>
  <div class="avatar-full-description__horizontal">
    <Avatar
      v-if="avatarUrl"
      :size="size"
      class="avatar-full-description__avatar"
      :src="avatarUrl"
      title=""
    />
    <div class="avatar-full-description__name-wrapper">
      <div class="d-flex align-center">
        <BaseIcon
          v-if="isPinned"
          icon-name="thumb-tack"
          type="danger"
          class="avatar-full-description__pin"
        />
        <TextLink v-if="showPath" :href="showPath" data-test-id="fullname-element">
          {{ fullName }}
        </TextLink>
        <span v-else>
          {{ fullName }}
        </span>
      </div>
      <ToolTip
        v-if="onBreak"
        icon="t-ringer-silent-sleeping"
        :title="t('vue_templates.user_profile.user_profile_details.user_on_break_message')"
        type="muted"
      />
      <small v-if="jobTitle" class="text-muted">
        {{ jobTitle }}
      </small>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar/Index.vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import TextLink from '@/components/atoms/TextLink';
import ToolTip from '@/components/atoms/ToolTip';
import i18n from '@/mixins/i18n';

export default {
  name: 'AvatarFullDescription',

  components: {
    Avatar,
    BaseIcon,
    TextLink,
    ToolTip,
  },
  mixins: [i18n],

  props: {
    avatarUrl: String,
    isPinned: Boolean,
    showPath: String,
    fullName: String,
    onBreak: {
      type: Boolean,
      default: false,
    },
    jobTitle: String,
    size: {
      type: String,
      default: 'large',
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-full-description__pin {
  height: auto;
  top: auto;
  margin-right: 5px;
}

.avatar-full-description__horizontal {
  display: flex;
  flex-direction: row;
}

.avatar-full-description__avatar {
  margin: 2px 15px 0 0;
}

.avatar-full-description__name-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
