<script lang="ts">
export default {
  name: 'PopoverMenuLink',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
import { BaseMenuLink } from '@/json_schema_types/sidebar_navigation';
defineProps<{ item: BaseMenuLink }>();
</script>

<template>
  <a class="popover-menu-item__link" :href="item.url">
    <FontAwesomeIcon v-if="item.icon" :icon="`${item.icon} fa-regular`" size="lg" />
    <span>{{ item.label }}</span>
  </a>
</template>

<style lang="scss" scoped>
.popover-menu-item__link {
  all: unset;
  color: $black;
  display: flex;
  flex: 1;
  width: 100%;
  gap: 1rem;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: $platinum;
  }
}
</style>
