<script lang="ts">
export default {
  name: 'NavigationBar',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import BurgerMenu from '@/components/atoms/BurgerMenu/Index.vue';
import NavigationTray from '@/components/molecules/NavigationTray/Index.vue';
import QuickSearchInput from '@/components/molecules/QuickSearchInput/Index.vue';
import QuickSearchResults from '@/components/molecules/QuickSearchResults/Index.vue';
import { useI18n } from '@/composables/useI18n';
import { ICurrentUser } from '@/types/Navigation';
import {
  PrimaryNavLinkGroup,
  SupportAndUpdates,
  UserMenu,
  UtilityNavItems,
} from '@/json_schema_types/sidebar_navigation';

const props = defineProps<{
  logoUrl: string;
  rootPath: string;
  searchPath: string;
  contentDiscoverabilitySearch: boolean;
  companyName: string;
  utilityNavItems: UtilityNavItems;
  primaryNavItems: PrimaryNavLinkGroup[];
  addNewItems?: PrimaryNavLinkGroup[];
  currentUser: ICurrentUser;
  userMenu?: UserMenu;
  supportAndUpdates?: SupportAndUpdates;
}>();

const { t } = useI18n();

const navHeaderLinks = [
  {
    label: 'Profile & settings',
    url: props.currentUser.show_path,
    avatarUrl: props.currentUser.avatar,
  },
  {
    label: 'Beta features',
    url: '/beta-features',
    icon: 'fa-flask',
  },
  {
    label: 'Notifications',
    url: '/notifications',
    icon: 'fa-bell',
  },
];

const searchTerm = ref('');

enum Panels {
  ADD_NEW = 'addNew',
  MAIN_NAV = 'mainNav',
  SEARCH = 'search',
}

const openPanels = ref({
  [Panels.ADD_NEW]: false,
  [Panels.MAIN_NAV]: false,
  [Panels.SEARCH]: false,
});

const togglePanel = (panel) => {
  Object.keys(openPanels.value).forEach((key) => {
    if (key === panel) {
      openPanels.value[key] = !openPanels.value[key];
    } else {
      openPanels.value[key] = false;
    }
  });
};
</script>

<template>
  <nav class="navigation-bar__container">
    <div class="navigation-bar__items">
      <!-- TODO replace with a new square image -->
      <a :href="rootPath" class="navigation-bar__company-logo">
        <img :src="logoUrl" :alt="companyName" />
      </a>
      <company-switcher
        v-if="utilityNavItems && utilityNavItems.company_switcher"
        :search-endpoint="utilityNavItems.company_switcher.search_endpoint"
        :admin="utilityNavItems.company_switcher.admin"
        :default-error-msg="t('errors.internal_server_error.something_went_wrong')"
      />
    </div>
    <div class="navigation-bar__items">
      <BaseButton
        variant="button-icon"
        prepend-icon="search"
        :rounded="false"
        class="navigation-bar__search-button"
        @click="togglePanel(Panels.SEARCH)"
      />
      <BaseButton
        text="Add new"
        :rounded="false"
        class="navigation-bar__add-new-button"
        @click="togglePanel(Panels.ADD_NEW)"
      />
      <BurgerMenu
        :open="openPanels.mainNav"
        class="navigation-bar__menu"
        @click="togglePanel(Panels.MAIN_NAV)"
      />
    </div>
  </nav>
  <NavigationTray
    :open="openPanels.mainNav"
    :header-links="navHeaderLinks"
    :nav-items="primaryNavItems"
    :support-and-updates="supportAndUpdates"
  />
  <NavigationTray
    v-if="addNewItems"
    :open="openPanels.addNew"
    :nav-items="addNewItems[0].links[0].children"
    theme="light"
  />
  <NavigationTray class="p-a-1" :open="openPanels.search" theme="light">
    <QuickSearchInput
      v-model="searchTerm"
      :search-path="searchPath"
      :content-discoverability-search="contentDiscoverabilitySearch"
    />
    <QuickSearchResults v-model="searchTerm" />
  </NavigationTray>
  <div id="subnavs"></div>
  <div class="main-content-container">
    <slot />
  </div>
</template>

<style lang="scss">
.main-content-container {
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  top: var(--navigation-height);
  left: 0;

  #main-content {
    height: calc(100% - var(--navigation-height));
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>

<style lang="scss" scoped>
.navigation-bar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navigation--height);
  max-height: var(--navigation--height);
  z-index: 10;
  padding: 10px 20px;
  background-color: var(--navigation-color);
}

.navigation-bar__company-logo {
  display: inline-block;
  margin-right: 20px;
  img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }
}

.navigation-bar__items {
  // TODO - temporary hacks to always show the switcher and
  // style it correctly. This can be removed once fully
  // switched to the new nav. Further details in the comments
  & ::v-deep {
    // TODO - temporary hacks to manipulate the account image
    // Replace this with a new small square image
    .navbar-brand {
      padding: 0 !important;
      max-width: 38px !important;
      background-size: contain;
      overflow: hidden;
      border-radius: 6px;
    }

    // So previously, the switch did not work on mobile, so was just
    // hidden from view. The colour also would not match the nav text
    // colour.
    .hidden-nav-switcher {
      display: block !important;
      a#switch-dropdown {
        color: var(--navigation-text-color) !important;
      }
    }

    .navbar__link--dropdown {
      padding: 0 !important;
    }

    // There were some odd settings on mobile width, so just
    // unset the old width and set a new max-width which is either
    // 90vw or 360px, whichever is smaller. The top value is just
    // to line up with the bottom of the current nav.
    .dropdown-menu--search {
      width: unset !important;
      max-width: calc(min(75vw, 360px));
      top: 43px !important;
    }

    // Again for some reason the nav was made not scrollable on mobile
    // meaning if you were using on mobile it would be impossible to
    // see the rest of the results. Undo this.
    .dropdown-menu--no-scroll {
      overflow: scroll !important;
    }
  }

  height: 100%;
  display: flex;
  align-items: center;
  gap: 22px;
}
</style>
